import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from 'components/post/TemplateProvider'

import "../components/postIndex/postIndex.scss"

const header = ['post', 'type', 'place name', 'place id'];

const BlogIndex = ({ data }) => {
  //console.log(props, data)
  const [rows, setRows] = useState([])

  // TODO: Move this to a reporting layer
  useEffect(() => {

    (async () => {
      //console.log('data ', data.allWpPost)
      //const posts = await wordpress.getPosts(undefined, undefined, 100)

      const posts = data.allWpPost.edges
      //console.log('Fetched all posts? ', posts)

      let rows = []

      posts.forEach(node => {
        let post = node.node
        //console.log('post: ', post)

        const title = post.title
        const category = post.categories.nodes[0].name

        const singCardBlocks = post.blocks.filter(block => block.__typename === "WpAcfSingCardsBlock")

        singCardBlocks.forEach(block => {
          block.singCards.posts.forEach(place => {
            //console.log('place for row ', place)
            let row = [title, category, place.title, place.placeId]
            rows.push(row)
            //console.log('row ', row)
          })
        })

        setRows(rows)

      })

      console.log(header.toString() + '\n' + rows.join('\n'))


    })();

  }, [])

  //console.log('page sections: ', pageData.sections.sections)

  return (
    <div>
      <table>
        <thead>
          {header.map(col => <th>{col}</th>)}
        </thead>
        {rows.length > 0 &&
          <tbody>
          {rows.map(row =>
            <tr>{row.map(cell => <td>{cell}</td>)}</tr>
          )}
          </tbody>
        }
      </table>
    </div>
  )
}

export default BlogIndex

export const placesByPost = graphql`
  query PlacesByPost {
    allWpPost(sort: {order: DESC, fields: date}) {
      totalCount
      edges {
        node {
          id
          title
          date
          excerpt
          content
          slug
          uri
          blocks {
            ... on WpAcfSingCardBlock {
              singCard {
                placeId
              }
            }
            ... on WpAcfSingCardsBlock {
              singCards {
                posts {
                  title
                  placeId
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              caption
              mediaItemUrl
            }
          }
          postDetails {
            city {
              ... on WpCity {
                title
                databaseId
              }
            }
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
              }
            }
          }
        }
      }
    }
  }
`
